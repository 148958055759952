<template>
  <div>
    <section>
      <b-row class="mb-2 mt-2">
        <b-col cols="4" class="mt-1">
          <span>فلترة حسب </span>
        </b-col>
        <b-col cols="4">
          <div>
            <ek-date-picker
              v-model="filter.subscriptionDate"
              range
              name="subscriptionDate"
              placeholder=" تاريخ التسجيل "
            >
            </ek-date-picker>
          </div>
        </b-col>
        <b-col cols="4">
          <div>
            <ek-date-picker
              v-model="filter.expirationDate"
              range
              name="expirationDate"
              placeholder=" تاريخ نهاية الإشتراك"
            >
            </ek-date-picker>
          </div>
        </b-col>
      </b-row>
    </section>
    <div>
      <ek-table
        selectedLabel="accountId"
        @delete-selected="deleteRange"
        :items="usersList.filter(filterUsers)"
        :columns="cols"
        @details="goToDetails"
        deleteBtn
      >
        <template slot="items.endSub" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>

        <template slot="items.startSub" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>

        <template slot="items.dateCreate" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>

        <template slot="items.accountStatuses" slot-scope="{ value }">
          <StatusBadge
            :statusList="accountStatus"
            :selectedStatusNumber="value"
          ></StatusBadge>
        </template>
      </ek-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";

export default {
  mixins: [dateManipulatoin],
  computed: {
    ...mapState({
      users: (state) => state.users.users,
      accountStatus: (state) => state.users.accountStatus,
    }),
    ...mapGetters(["usersList"]),
  },
  components: {
    StatusBadge,
  },
  beforeDestroy() {
    this.$store.commit("Reset_Search_Dto");
  },
  data: () => ({
    cols: [
      {
        label: "الاسم ",
        field: "accountName",
      },
      {
        label: " اسم المستخدم    ",
        field: "userName",
      },
      {
        label: "تاريخ التسجيل",
        field: "dateCreate",
      },
      {
        label: "عدد الإشتراكات",
        field: "subCount",
      },
      {
        label: "بداية الإشتراك",
        field: "startSub",
      },
      {
        label: "نهاية الإشتراك",
        field: "endSub",
      },
      {
        label: "حالة الحساب",
        field: "accountStatuses",
      },
      {
        label: "التفاصيل ",
        field: "details",
      },
    ],
    filter: {
      subscriptionDate: "",
      expirationDate: "",
    },
  }),
  created() {
    this.$store.dispatch("getUsersList");
  },
  methods: {
    ...mapActions(["getUsersList", "deleteRange"]),
    goToDetails({ row }) {
      this.$router.push({
        path: `/admin/users/details/${row.accountId}`,
        query: { username: row.accountName, id: row.accountId },
      });
    },

    filterUsers(item) {
      let [subscriptionStartDate = null, subscriptionEndDate = null] =
        this.filter.subscriptionDate
          ?.replace("to", "")
          .replace(" ", "")
          .split(" ");

      let [expirationStartDate = null, expirationEndDate = null] =
        this.filter.expirationDate
          ?.replace("to", "")
          .replace(" ", "")
          .split(" ");

      return (
        ((this.setDateTime(new Date(item.dateCreate)) >=
          this.setDateTime(new Date(subscriptionStartDate)) &&
          this.setDateTime(new Date(item.dateCreate)) <=
            this.setDateTime(new Date(subscriptionEndDate))) ||
          this.filter.subscriptionDate.length == 0) &&
        ((this.setDateTime(new Date(item.endSub)) >=
          this.setDateTime(new Date(expirationStartDate)) &&
          this.setDateTime(new Date(item.endSub)) <=
            this.setDateTime(new Date(expirationEndDate))) ||
          this.filter.expirationDate.length == 0)
      );
    },
  },
};
</script>
