export const dateManipulatoin = {

    methods: {
        setDateTime(date) {
            let ndate = new Date(date)
            ndate.setHours(12)
            ndate.setMinutes(0)
            ndate.setSeconds(0)
            ndate.setMilliseconds(0)
            return ndate
        },
        formatDate(date) {
            let newDate = `${new Date(date).getFullYear()}-${
                new Date(date).getMonth() + 1
            }-${new Date(date).getDate()}`;
            newDate += `  `;
        
            return newDate;
        },
    },
    

}
